import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type Locale } from '@lib/language'
import { getLinkPageUrl } from '@lib/routes'
import { type LocalizedShop, ShopContext } from '@lib/shop-context'
import { emptyCart, getCartIdStorageKey, loadCart } from './helpers'
import {
  useAddItemsToCart,
  useRemoveItemFromCart,
  useSubmitCart,
  useUpdateCartItem,
} from './hooks'
import {
  type Cart,
  type CartFormValues,
  type CartVariantLineItem,
  type SubmitCartResponse,
} from './types'

interface CartContextProps {
  cart: Cart
  isCartOpen: boolean
  isCartUpdating: boolean
  isCartProductAdding: boolean
  isCartSubmitting: boolean
  openCartInModal: boolean
  openCartAfterAddingItems: boolean
  toggleCart: (newState: boolean) => void
  addItemsToCart: (variantLineItems: CartVariantLineItem[]) => Promise<boolean>
  updateCartItem: (id: string, quantity: number) => Promise<boolean>
  removeItemFromCart: (id: string) => Promise<boolean>
  submitCart: (values: CartFormValues) => Promise<SubmitCartResponse>
}

interface CartContextProviderProps {
  site: SanitySiteFragment
  localizedShop: LocalizedShop
  locale: Locale
  children: ReactNode
}

export const CartContext = createContext<CartContextProps>({
  cart: emptyCart,
  isCartOpen: false,
  isCartUpdating: false,
  isCartProductAdding: false,
  isCartSubmitting: false,
  openCartInModal: true,
  openCartAfterAddingItems: false,
  toggleCart: () => null,
  addItemsToCart: async () => false,
  updateCartItem: async () => false,
  removeItemFromCart: async () => false,
  submitCart: async () => ({
    errors: {},
  }),
})

/**
 * The cart context provider.
 */
export const CartContextProvider = ({
  site,
  localizedShop,
  locale,
  children,
}: CartContextProviderProps) => {
  const { shopifyStorefrontClient } = useContext(ShopContext)

  const router = useRouter()

  const [cart, setCart] = useState<Cart>(emptyCart)
  const [openCartInModal, setOpenCartInModal] = useState(
    !site.cartSettings?.openInSeparatePage,
  )
  const [openCartAfterAddingItems, setOpenCartAfterAddingItems] = useState(
    !!site.cartSettings?.openAfterAddingItems,
  )
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isCartUpdating, setIsCartUpdating] = useState(false)
  const [isCartProductAdding, setIsCartProductAdding] = useState(false)
  const [isCartSubmitting, setIsCartSubmitting] = useState(false)
  const [localeInitialised, setLocaleInitialised] = useState('')

  // Update callbacks
  const saveCart = useCallback((locale: Locale, cart?: Cart) => {
    if (!cart) {
      return
    }

    setCart(cart)

    const cartIdStorageKey = getCartIdStorageKey(locale)
    const cartId = cart.id

    if (typeof window !== `undefined`) {
      localStorage.setItem(cartIdStorageKey, cartId)
    }
  }, [])

  const toggleCart = useCallback(
    (newState: boolean) => {
      if (!openCartInModal) {
        if (newState) {
          const url = getLinkPageUrl('cartPage')
          router.push(url, url, {
            locale,
          })
        }

        return
      }

      if (isCartOpen !== newState) {
        setIsCartOpen(newState)
      }
    },
    [isCartOpen, openCartInModal, locale, router],
  )

  const addItemsToCart = useAddItemsToCart(
    site,
    cart,
    locale,
    setIsCartProductAdding,
    setIsCartUpdating,
    saveCart,
    toggleCart,
  )
  const updateCartItem = useUpdateCartItem(
    cart,
    locale,
    setIsCartUpdating,
    saveCart,
  )
  const removeItemFromCart = useRemoveItemFromCart(
    cart,
    locale,
    setIsCartUpdating,
    saveCart,
  )
  const submitCart = useSubmitCart(cart, setIsCartSubmitting)

  // Load initial cart from Shopify
  useEffect(() => {
    const loadCartWrapper = async () => {
      if (
        !localizedShop.shop ||
        localeInitialised === locale ||
        localizedShop.locale !== locale
      ) {
        return
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setLocaleInitialised(locale)
      const loadedCart = await loadCart(
        locale,
        shopifyStorefrontClient,
        localizedShop.shop,
      )
      saveCart(locale, loadedCart)
    }
    loadCartWrapper()
  }, [
    locale,
    localeInitialised,
    saveCart,
    localizedShop,
    shopifyStorefrontClient,
  ])

  // Load cart settings (update when switching language)
  useEffect(() => {
    setOpenCartInModal(!site.cartSettings?.openInSeparatePage)
  }, [site.cartSettings?.openInSeparatePage])
  useEffect(() => {
    setOpenCartAfterAddingItems(!!site.cartSettings?.openAfterAddingItems)
  }, [site.cartSettings?.openAfterAddingItems])

  return (
    <CartContext.Provider
      value={{
        cart,
        isCartOpen,
        isCartUpdating,
        isCartProductAdding,
        isCartSubmitting,
        openCartInModal,
        openCartAfterAddingItems,
        toggleCart,
        addItemsToCart,
        updateCartItem,
        removeItemFromCart,
        submitCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
