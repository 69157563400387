import axios from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  type SanityProductGalleryPhoto,
  type SanityProductOption,
  type SanityProductOptionName,
  type SanityProductVariantFragment,
  type SanityProductFragment,
  type SanityProductOptionSetting,
  type SanityProductVariantOption,
  type SanityProductCatalogueQuery,
} from '@data/sanity/queries/types/product'
import { type ProductInventory } from './shopify/product'
import { useUser } from './auth'
import { hasObject, parseOptionalParameter } from './helpers'
import { usePrevious, useUrlParameters } from './hooks'
import { type Locale } from './language'
import { SiteContext } from './site-context'
import { type UserProduct } from './user'

export interface Filter {
  name: string
  values: string[]
}

export interface FilterValue {
  name: string
  value: string
}

interface ProductInventoryRequest {
  localeHeader: Locale
  url: string
  id?: number
}

/**
 * Gets product gallery photos for selected variant.
 */
export const getProductGalleryPhotos = (
  photosets: SanityProductGalleryPhoto[],
  variant?: SanityProductVariantFragment,
): SanityImageFragment[] => {
  const variantPhotoset = photosets.find(({ forOption }) => {
    const option = forOption
      ? {
          name: forOption.split(':')[0],
          value: forOption.split(':')[1],
        }
      : {}

    return option.value && variant && hasObject(variant.options, option)
  })

  if (variantPhotoset?.photos && variantPhotoset.photos.length > 0) {
    return variantPhotoset.photos
  }

  return photosets.find(({ forOption }) => !forOption)?.photos ?? []
}

/**
 * Gets a list of user's digital products from product catalogue filtered by IDs.
 */
export const getUserDigitalProducts = (
  productCatalogue: SanityProductCatalogueQuery,
  productIds: number[],
  activeSubscriptionProductIds: number[],
): UserProduct[] => {
  const digitalProducts: UserProduct[] = []

  productCatalogue
    .filter((product) => productIds.includes(product.productID))
    .forEach((product) => {
      switch (product.type) {
        case 'digital': {
          return digitalProducts.push({
            id: product.productID,
            title: product.title,
            slug: product.slug.current,
          })
        }

        case 'subscription': {
          return product.subscriptionProductIds
            ?.filter((productId) =>
              activeSubscriptionProductIds.includes(productId),
            )
            ?.forEach((productId) => {
              productCatalogue
                .filter(
                  (childProduct) =>
                    childProduct.productID === productId &&
                    childProduct.type === 'digital',
                )
                .forEach((childProduct) => {
                  digitalProducts.push({
                    id: childProduct.productID,
                    title: childProduct.title,
                    slug: childProduct.slug.current,
                  })
                })
            })
        }
      }
    })

  return digitalProducts
}

/**
 * Converts Sanity product or variant ID string into Shopify ID number.
 */
export const sanityProductIdToShopifyId = (sanityProductId: string) => {
  const shopifyProductId = sanityProductId.split('-')?.[1]

  if (!shopifyProductId) {
    return null
  }

  return Number(shopifyProductId)
}

/**
 * Gets product option label.
 */
export const getOptionLabel = (
  optionNames: SanityProductOptionName[],
  option: SanityProductOption,
) =>
  optionNames?.find(({ forOption }) => forOption === option.name)?.name ||
  option.name

/**
 * Digital product content type hook.
 */
export const useDigitalProductContent = (
  productCatalogue: SanityProductCatalogueQuery,
  productSlug: string,
) => {
  const { user } = useUser()

  const isDigitalProduct = useMemo(() => {
    const product = productCatalogue.find(
      ({ slug }) => slug.current === productSlug,
    )
    return product?.type === 'digital'
  }, [productCatalogue, productSlug])

  const [userDigitalProducts, setUserDigitalProducts] = useState<
    UserProduct[] | null
  >(null)
  const [showPrivateContent, setShowPrivateContent] = useState(false)
  const [isLoaded, setIsLoaded] = useState(!isDigitalProduct)

  // Load digital products owned by current user, if this is a digital product
  useEffect(() => {
    if (!isDigitalProduct) {
      return
    }

    if (user && !user.isLoggedIn) {
      setUserDigitalProducts([])
      return
    }

    if (user && user.isLoggedIn) {
      const userProductIds = user.ownedProducts?.map(({ id }) => id) ?? []
      const userSubscriptionProductIds = user.activeSubscriptionProductIds ?? []
      const newUserDigitalProducts = getUserDigitalProducts(
        productCatalogue,
        userProductIds,
        userSubscriptionProductIds,
      )

      setUserDigitalProducts(newUserDigitalProducts)
      return
    }
  }, [productCatalogue, isDigitalProduct, user])

  // Detect public or private content, if this is a digital product
  useEffect(() => {
    if (!isDigitalProduct || userDigitalProducts === null) {
      return
    }

    setShowPrivateContent(
      userDigitalProducts.some(({ slug }) => slug === productSlug),
    )
    setIsLoaded(true)
  }, [productSlug, isDigitalProduct, userDigitalProducts])

  return [showPrivateContent, isLoaded] as const
}

/**
 * Gets default product option.
 */
export const getDefaultOption = (
  options: SanityProductOption[],
  optionSettings: SanityProductOptionSetting[],
): SanityProductVariantOption | undefined => {
  if (options.length === 0) {
    return
  }

  const firstOption = options?.[0]
  const defaultOption = {
    name: firstOption?.name,
    value: firstOption?.values?.[0],
    position: firstOption?.position,
  }

  if (optionSettings.length === 0) {
    return defaultOption
  }

  // Use first option setting to find default option
  const settingParts = optionSettings[0].forOption?.split(':')
  const name = settingParts?.[0]
  const value = settingParts?.[1]
  const position = options.find((option) => option.name === name)?.position

  if (
    typeof name === 'undefined' ||
    typeof value === 'undefined' ||
    typeof position === 'undefined'
  ) {
    return defaultOption
  }

  return { name, value, position }
}

/**
 * Gets a product variant by the default option.
 */
export const getVariantByDefaultOption = (
  variants: SanityProductVariantFragment[],
  defaultOption?: SanityProductVariantOption,
) => {
  if (!defaultOption) {
    return null
  }

  const variant = variants.find(({ options }) =>
    hasObject(options, defaultOption),
  )

  return variant ?? null
}

/**
 * Product with inventory data hook.
 */
export const useProductWithInventory = (
  locale: Locale,
  product?: SanityProductFragment,
): SanityProductFragment | undefined => {
  // Read product inventory
  const productInventoryRequest: ProductInventoryRequest = {
    localeHeader: locale,
    url: '/api/shopify/product-inventory',
    id: product?.productID,
  }
  const { data: productInventory } = useSWR<ProductInventory | undefined>(
    productInventoryRequest,
    async ({ localeHeader, url, id }: ProductInventoryRequest) => {
      if (!id) {
        return
      }

      const response = await axios.get<ProductInventory>(url, {
        params: {
          id,
        },
        headers: {
          'X-Locale': localeHeader,
        },
      })

      return response.data
    },
    {
      errorRetryCount: 3,
    },
  )

  return useMemo(() => {
    if (!product || !productInventory) {
      return product
    }

    const productVariants = product.variants ?? []

    return {
      ...product,
      inStock: productInventory.inStock,
      lowStock: productInventory.lowStock,
      variants: productVariants.map((productVariant) => {
        const productInventoryVariant = productInventory.variants.find(
          ({ id }) => id === productVariant.variantID,
        )

        if (!productInventoryVariant) {
          return productVariant
        }

        return {
          ...productVariant,
          ...productInventoryVariant,
        }
      }),
    }
  }, [product, productInventory])
}

/**
 * Active product variant hook.
 */
export const useActiveVariant = (product?: SanityProductFragment) => {
  const { isRouteChanging } = useContext(SiteContext)

  const defaultVariantId = useMemo(() => {
    if (!product) {
      return null
    }

    const defaultOption = getDefaultOption(
      product.options,
      product.optionSettings ?? [],
    )
    const firstVariant = product.variants?.[0] ?? null
    const defaultVariant =
      getVariantByDefaultOption(product.variants ?? [], defaultOption) ??
      firstVariant
    return defaultVariant?.variantID ?? null
  }, [product])

  const [currentParameters, setCurrentParameters] = useUrlParameters([
    {
      name: 'variant',
      value: defaultVariantId ? `${defaultVariantId}` : null,
    },
  ])

  // Manage URL parameters
  const previousParameters = usePrevious(currentParameters)
  const activeParameters = useMemo(() => {
    return isRouteChanging && previousParameters
      ? previousParameters
      : currentParameters
  }, [currentParameters, previousParameters, isRouteChanging])

  // Find active variant
  const variantIds = useMemo(() => {
    return product?.variants?.map(({ variantID }) => variantID) ?? []
  }, [product?.variants])

  const activeVariantId = useMemo(() => {
    const parameterVariant = activeParameters.find(
      ({ name }) => name === 'variant',
    )
    const parameterVariantValue = parseOptionalParameter<string>(
      parameterVariant?.value,
    )
    const parameterVariantId = parameterVariantValue
      ? Number(parameterVariantValue)
      : null

    return variantIds.some((id) => id == parameterVariantId)
      ? parameterVariantId
      : defaultVariantId
  }, [activeParameters, defaultVariantId, variantIds])

  const activeVariant = useMemo(() => {
    return product?.variants?.find(
      ({ variantID }) => variantID === activeVariantId,
    )
  }, [product?.variants, activeVariantId])

  // Handle variant change
  const updateProductPageUrl = useCallback(
    (variantId: number) => {
      const isValidVariant = variantIds.some((id) => id === variantId)

      setCurrentParameters([
        ...activeParameters.filter(({ name }) => name !== 'variant'),
        {
          name: 'variant',
          value: isValidVariant ? `${variantId}` : `${defaultVariantId}`,
        },
      ])
    },
    [activeParameters, defaultVariantId, setCurrentParameters, variantIds],
  )

  return [activeVariant, updateProductPageUrl] as const
}
