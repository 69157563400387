import cx from 'classnames'
import { useContext } from 'react'

import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface CartSubtotalProps {
  price: number
  className?: string
}

const CartSubtotal = ({ price, className }: CartSubtotalProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx('flex justify-between text-sm font-semibold', className)}
    >
      <span>{strings.cartSubtotal}</span>
      <span className="uppercase">
        {currency}
        {getPrice(price, taxRate)}
      </span>
    </div>
  )
}

export default CartSubtotal
