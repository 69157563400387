import axios, { type AxiosResponse } from 'axios'

import {
  type FrameBuilderProduct,
  type FrameBuilderProductConfiguration,
  ProductValidationError,
} from './product'
import { type FrameBuilderState } from './state'
import {
  getIsProductPassepartoutSizeValid,
  getIsProductProfileAvailableForArtSize,
  getIsProductProfileAvailableForPassepartoutColor,
  getIsProductProfileAvailableForTexture,
} from './validation/product-is-valid'

interface FrameBuilderArtworkPayload {
  width: number
  height: number
}

interface FrameBuilderPassepartoutPayload {
  sizeId?: string
  colorId?: string
}

interface FrameBuilderExtrasPayload {
  active: boolean
  extraServiceIds: string[]
  spacer: {
    sizeId?: string
    colorId?: string
  }
}

export interface FrameBuilderPayload {
  artwork: FrameBuilderArtworkPayload
  textureId?: string
  profileId?: string
  passepartout: FrameBuilderPassepartoutPayload
  glassId?: string
  extras: FrameBuilderExtrasPayload
  image: string
}

export interface ShopifyCreateFrameProductResponse {
  productId: string
  variantId: string
}

export const shopifyFrameProductType = 'Generated Frame'

/**
 * Gets Shopify ID from Shopify global ID.
 */
export const parseShopifyGlobalId = (shopifyGlobalId: string) => {
  try {
    return Number(shopifyGlobalId.split('?')[0].split('/').slice(-1)[0])
  } catch (_) {
    return
  }
}

const getProductPayload = (state: FrameBuilderState, imageDataUri: string) => {
  const productPayload: FrameBuilderPayload = {
    artwork: {
      width: state.artwork.width,
      height: state.artwork.height,
    },
    textureId: state.texture?._id,
    profileId: state.profile?._id,
    passepartout: {
      sizeId: state.passepartout.size?._id,
      colorId: state.passepartout.color?._id,
    },
    glassId: state.glass?._id,
    extras: {
      active: !!state.extras.active,
      extraServiceIds:
        state.extras.extraServices?.map((extraService) => extraService._id) ??
        [],
      spacer: {
        sizeId: state.extras.spacer.size?._id,
        colorId: state.extras.spacer.color?._id,
      },
    },
    image: imageDataUri,
  }

  return productPayload
}

export const createFrameProduct = async (
  locale: string,
  state: FrameBuilderState,
  imageDataUri: string
) => {
  try {
    const productPayload = getProductPayload(state, imageDataUri)
    const payload = JSON.stringify(productPayload)

    const response = await axios.post<
      ShopifyCreateFrameProductResponse,
      AxiosResponse<ShopifyCreateFrameProductResponse>,
      string
    >('/api/shopify/create-frame-product', payload, {
      headers: {
        'Content-Type': 'application/json',
        'X-Locale': locale,
      },
    })

    if (response.status !== 200) {
      throw new Error(JSON.stringify(response.data))
    }

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getProductFromPayload = (
  frameBuilderPayload: FrameBuilderPayload,
  configuration: FrameBuilderProductConfiguration
) => {
  // Resolve actual profiles and textures
  const profile = configuration.profiles.find(
    ({ _id }) => _id === frameBuilderPayload.profileId
  )
  const texture = configuration.textures.find(
    ({ _id }) => _id === frameBuilderPayload.textureId
  )

  // Resolve actual passepartout sizes & colors
  const passepartoutSize = configuration.passepartoutSizes.find(
    ({ _id }) => _id === frameBuilderPayload.passepartout.sizeId
  )
  const passepartoutColor = configuration.passepartoutColors.find(
    ({ _id }) => _id === frameBuilderPayload.passepartout.colorId
  )

  // Resolve actual glass
  const glass = configuration.glass.find(
    ({ _id }) => _id === frameBuilderPayload.glassId
  )

  // Resolve actual spacer sizes & colors
  const spacerSize = configuration.spacerSizes.find(
    ({ _id }) => _id === frameBuilderPayload.extras.spacer.sizeId
  )
  const spacerColor = configuration.spacerColors.find(
    ({ _id }) => _id === frameBuilderPayload.extras.spacer.colorId
  )

  const frameWidth =
    frameBuilderPayload.artwork.width + (passepartoutSize?.size ?? 0) * 2
  const frameHeight =
    frameBuilderPayload.artwork.height + (passepartoutSize?.size ?? 0) * 2

  if (typeof texture === 'undefined') {
    throw new ProductValidationError('Frame texture is missing.')
  }

  if (typeof profile === 'undefined') {
    throw new ProductValidationError('Frame profile is missing.')
  }

  if (typeof passepartoutSize === 'undefined') {
    throw new ProductValidationError('Frame passepartout size is missing.')
  }

  if (typeof glass === 'undefined') {
    throw new ProductValidationError('Frame glass is missing.')
  }

  if (
    !getIsProductProfileAvailableForArtSize([frameWidth, frameHeight], profile)
  ) {
    throw new ProductValidationError('Frame profile is invalid.')
  }

  if (!getIsProductProfileAvailableForTexture(profile, texture)) {
    throw new ProductValidationError(
      'Frame profile and texture are incompatible.'
    )
  }

  if (
    !getIsProductPassepartoutSizeValid(
      frameWidth,
      frameHeight,
      passepartoutSize,
      passepartoutColor
    )
  ) {
    throw new ProductValidationError('Frame passepartout size is invalid.')
  }

  if (
    passepartoutSize.size > 0 &&
    (typeof passepartoutColor === 'undefined' ||
      !getIsProductProfileAvailableForPassepartoutColor(
        profile,
        passepartoutColor
      ))
  ) {
    throw new ProductValidationError('Frame passepartout color is invalid.')
  }

  const product: FrameBuilderProduct = {
    artwork: {
      width: frameBuilderPayload.artwork.width,
      height: frameBuilderPayload.artwork.height,
    },
    profile,
    texture,
    passepartout: {
      size: passepartoutSize,
      color: passepartoutColor,
    },
    glass: glass,
    extras: {
      active: frameBuilderPayload.extras.active,
      extraServices: [
        ...configuration.extraServices.filter((extraService) =>
          frameBuilderPayload.extras.extraServiceIds.some(
            (extraServiceId) => extraServiceId === extraService._id
          )
        ),
      ],
      spacer: {
        size: spacerSize,
        color: spacerColor,
      },
    },
  }

  return product
}
