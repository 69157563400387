import cx from 'classnames'
import NextImage from 'next/image'
import { type CSSProperties } from 'react'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { borderRadiusClassMap } from '@lib/border'
import {
  type ImageDimensions,
  getImageDimensions,
  getSanityImageLoader,
} from '@lib/image'

interface PhotoProps {
  image: SanityImageFragment
  width?: number
  height?: number
  sizes?: string
  fill?: boolean
  priority?: boolean
  unoptimized?: boolean
  quality?: number
  className?: string
  imageClassName?: string
  style?: CSSProperties
  imageStyle?: CSSProperties
}

const Photo = ({
  image,
  width,
  height,
  sizes,
  fill,
  priority,
  unoptimized,
  quality = 75,
  className,
  imageClassName,
  style,
  imageStyle,
}: PhotoProps) => {
  if (!image || !image.asset) {
    return null
  }

  const dimensions: ImageDimensions | undefined = !fill
    ? getImageDimensions(image, width, height)
    : undefined
  const lqip =
    'lqip' in image && image.lqip
      ? (image.lqip as `data:image/${string}`)
      : undefined

  return (
    <figure className={cx(className, 'photo-figure')} style={style}>
      <NextImage
        src={JSON.stringify(image)}
        loader={getSanityImageLoader}
        width={dimensions?.width}
        height={dimensions?.height}
        sizes={sizes}
        fill={!!fill}
        unoptimized={!!unoptimized}
        priority={!!image.priority || !!priority}
        quality={quality}
        placeholder={lqip}
        alt={image.alt ?? ''}
        className={cx(
          'block overflow-hidden',
          image.borderRadius
            ? borderRadiusClassMap[image.borderRadius ?? '']
            : '',
          imageClassName,
        )}
        style={imageStyle}
      />

      {image.caption && (
        <figcaption className="text-current text-sm my-2">
          {image.caption}
        </figcaption>
      )}
    </figure>
  )
}

export default Photo
