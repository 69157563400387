import { useContext } from 'react'
import cx from 'classnames'

import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

import Icon from '@components/icon'

interface CartDiscountProps {
  title: string
  amount: number
  quantity?: number
  className?: string
}

const CartDiscount = ({
  title,
  amount,
  quantity = 1,
  className,
}: CartDiscountProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx('flex justify-between text-sm font-semibold', className)}
    >
      <div className="flex items-center">
        {strings.cartDiscount && (
          <span className="mr-4">{strings.cartDiscount}</span>
        )}

        <span className="flex items-center">
          <Icon name="PriceTag" id="discount" className="w-5 block" />
          <span className="ml-2">{title}</span>
          {quantity && quantity > 1 && (
            <span className="ml-2 font-normal">x{quantity}</span>
          )}
        </span>
      </div>

      <span className="uppercase">
        -{currency}
        {getPrice(amount * quantity, taxRate)}
      </span>
    </div>
  )
}

export default CartDiscount
