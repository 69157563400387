import groq from 'groq'

export const vimeoVideoFragment = groq`
  "vimeoVideo": *[
    _type == "vimeo.videoAsset" &&
    _id == ^.source.id
  ][0],
`

export const muxVideoFragment = groq`
  muxVideo {
    asset->,
  },
`

export const videoFragment = groq`
  ...,
  ${muxVideoFragment}
  ${vimeoVideoFragment}
`
