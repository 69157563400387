import cx from 'classnames'
import {
  type ChangeEvent,
  type FocusEvent,
  type TextareaHTMLAttributes,
  useState,
} from 'react'

import { type CustomFormRegister } from './input-field'

interface TextAreaProps {
  formRegister: CustomFormRegister
  errorMessage?: string
  label?: string
  borderBottom?: boolean
  isInvertedColors?: boolean
  rows?: number
  inputClassName?: string
}

const TextArea = ({
  id,
  value,
  defaultValue = '',
  autoComplete,
  placeholder,
  label,
  borderBottom,
  errorMessage,
  formRegister,
  isInvertedColors,
  rows,
  className,
  inputClassName,
}: TextAreaProps & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const isManaged = typeof value !== 'undefined'
  const [currentValue, setCurrentValue] = useState(defaultValue)

  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left')}>
        {label && (
          <label htmlFor={id} className="text-xs mb-2">
            {label}
          </label>
        )}

        <textarea
          id={id}
          inputMode="text"
          autoComplete={autoComplete}
          ref={formRegister.ref}
          name={formRegister.name}
          rows={rows}
          value={isManaged ? value : currentValue}
          placeholder={placeholder}
          onBlur={(event: FocusEvent<HTMLTextAreaElement>) => {
            formRegister.onBlur(event)
            setCurrentValue(event.target.value)
          }}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            formRegister.onChange(event)
            setCurrentValue(event.target.value)
          }}
          className={cx(
            'relative appearance-none w-full h-full py-4 text-base leading-none',
            {
              'bg-input-bg text-input-text': !isInvertedColors,
              'bg-input-inverted-bg text-input-inverted-text': isInvertedColors,
              'border-error': errorMessage,
              'border-input-border': !errorMessage && !isInvertedColors,
              'border-input-inverted-border': !errorMessage && isInvertedColors,
              'mb-[1px] border-b focus-within:mb-0 focus-within:border-b-2':
                borderBottom,
              'px-4 border rounded-[3px]': !borderBottom,
            },
            inputClassName,
          )}
        >
          {value}
        </textarea>

        {errorMessage && (
          <span role="alert" className="mt-2 text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default TextArea
