import '../public/global.css'

import cx from 'classnames'
import { LazyMotion, domAnimation, AnimatePresence } from 'framer-motion'
import { type AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { Router } from 'next/router'
import Script from 'next/script'
import { type ReactNode, useEffect, useContext, useRef } from 'react'

import { type SanityPage } from '@data/sanity/queries/types/page'
import { type SanityProductCatalogueQuery } from '@data/sanity/queries/types/product'
import {
  type SanityGeneralSettings,
  type SanitySiteFragment,
} from '@data/sanity/queries/types/site'
import {
  pageLoadEventName,
  pageNavigationEventName,
  pageViewEventName,
  triggerGoogleTagManagerEvent,
} from '@lib/analytics'
import { pageTransitionSpeed } from '@lib/animate'
import { CartContext } from '@lib/cart/context'
import { getCheckoutUrlStorageKey } from '@lib/checkout'
import { gtWalsheim } from '@lib/fonts'
import { getRandomString } from '@lib/helpers'
import { type Locale } from '@lib/language'
import { LanguageContextProvider } from '@lib/language-context'
import { MetadataContextProvider } from '@lib/metadata-context'
import { type BasePageProps } from '@lib/page'
import { PartnerAdsContextProvider } from '@lib/partner-ads-context'
import { ProductContextProvider } from '@lib/product-context'
import { type Reviews } from '@lib/review'
import { getLinkPageUrl } from '@lib/routes'
import { ShopContextProvider } from '@lib/shop-context'
import { SiteContext, SiteContextProvider } from '@lib/site-context'
import { StringsContextProvider } from '@lib/strings-context'

import RouteChangeProgressBar from '@components/route-change-progress-bar'
import CartModal from '@modules/shop/cart/modal'

const PreviewProvider = dynamic(() => import('@lib/sanity/preview'))

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

interface NextHistoryState {
  url: string
  as: string
  options: TransitionOptions
}

type AppPageProps = BasePageProps & {
  site: SanitySiteFragment | null
  page: SanityPage | null
  productCatalogue?: SanityProductCatalogueQuery
  shopifyRedirect?: boolean
  reviews?: Reviews
}

type DefaultAppProps = AppProps<AppPageProps>

type CustomAppProps = DefaultAppProps & {
  pageProps: AppPageProps
}

type SiteProps = Pick<DefaultAppProps, 'router'> & {
  pageProps: AppPageProps
  children: ReactNode
}

interface ExternalScriptsProps {
  settings?: SanityGeneralSettings
}

/**
 * Loads external scripts using next/script.
 */
const ExternalScripts = ({ settings }: ExternalScriptsProps) => {
  const nonce =
    typeof window !== 'undefined'
      ? document
          .querySelector('[property="csp-nonce"]')
          ?.getAttribute('content')
      : null
  const pageLoadEventId = getRandomString()

  if (!nonce) {
    return null
  }

  return (
    <>
      {settings?.cookieBotId && (
        <Script
          id="Cookiebot"
          nonce={nonce}
          src="https://consent.cookiebot.com/uc.js"
          strategy="afterInteractive"
          data-cbid={settings.cookieBotId}
          data-blockingmode="auto"
        />
      )}

      {settings?.gtmContainerId && (
        <>
          <Script
            id="google-tag-manager-variables"
            nonce={nonce}
            strategy="afterInteractive"
          >
            {`
              window['dataLayer'] = window['dataLayer'] || []
              window['dataLayer'].push({ eventId: '${pageLoadEventId}' })
              window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
            `}
          </Script>
          <Script
            id="google-tag-manager"
            src={`https://www.googletagmanager.com/gtm.js?id=${settings.gtmContainerId}`}
            nonce={nonce}
            strategy="lazyOnload"
          />
        </>
      )}

      {settings?.facebookPixelId && (
        <>
          <Script
            id="facebook-pixel-variables"
            nonce={nonce}
            strategy="afterInteractive"
          >
            {`
              if (!window.fbq) {
                const n = function () {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                }
                window.fbq = n

                if (!window._fbq) {
                  window._fbq = n
                }

                n.push = n
                n.loaded = true
                n.version = '2.0'
                n.queue = []

                window.fbq('init', '${settings.facebookPixelId}')
                window.fbq('track', 'PageView')
              }
            `}
          </Script>
          <Script
            id="facebook-pixel"
            src="https://connect.facebook.net/en_US/fbevents.js"
            nonce={nonce}
            strategy="lazyOnload"
          />
        </>
      )}
    </>
  )
}

/**
 * Add new position to scroll positions.
 */
const addScrollPosition = (
  positions: Record<string, number>,
  locale: Locale,
  url: string,
  position: number,
) => {
  const key = `${locale}:${url}`
  const alternativeKey = `${locale}:/${locale}${url.replace(/\/+$/g, '')}`

  return {
    ...positions,
    [key]: position,
    [alternativeKey]: position,
  }
}

/**
 * Router event handler hook.
 */
const useRouterEvents = (router: Router, locale: Locale) => {
  const { toggleCart } = useContext(CartContext)
  const { toggleIsRouteChanging, toggleMobileMenu } = useContext(SiteContext)

  const scrollPositions = useRef<Record<string, number>>({})
  const shouldScrollRestore = useRef(false)
  const isInitialLoad = useRef(true)

  useEffect(() => {
    // Prevent browser scroll restoration
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY,
        )
      }

      delete event['returnValue']
    }

    function handleRouteChangeStart(_: string, { shallow }: TransitionOptions) {
      toggleMobileMenu(false)
      toggleCart(false)

      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY,
        )
      }

      // Check if URL is changing
      if (!shallow) {
        toggleIsRouteChanging(true)
      }
    }

    function handleRouteChangeComplete(
      url: string,
      { shallow }: TransitionOptions,
    ) {
      // Wait for page transition to complete
      setTimeout(() => toggleIsRouteChanging(false), pageTransitionSpeed)

      // Check if URL is changing
      if (!isInitialLoad.current && !shallow) {
        // Restore scroll position after route change completes
        const position = scrollPositions.current[`${locale}:${url}`]
        const top = position && shouldScrollRestore.current ? position : 0

        // Restore scroll position or set it to 0
        setTimeout(
          () => requestAnimationFrame(() => window.scrollTo({ top })),
          pageTransitionSpeed + 100,
        )

        shouldScrollRestore.current = false
      }

      // Wait for document title to update
      setTimeout(() => {
        triggerGoogleTagManagerEvent(pageViewEventName, {
          pagePath: window.location.pathname,
          pageTitle: document.title,
        })
        triggerGoogleTagManagerEvent(pageNavigationEventName, {
          pagePath: window.location.pathname,
          pageTitle: document.title,
        })
      }, pageTransitionSpeed + 101)

      isInitialLoad.current = false
    }

    function handleRouteChangeError() {
      toggleIsRouteChanging(false)
    }

    function handleBeforePopState({ options }: NextHistoryState) {
      // Allow scroll position restoring
      shouldScrollRestore.current = true
      options.scroll = false

      return true
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)
    router.beforePopState(handleBeforePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
      router.beforePopState(() => true)
    }
  }, [locale, router, toggleCart, toggleMobileMenu, toggleIsRouteChanging])
}

/**
 * Shopify redirect handler hook.
 */
const useShopifyRedirect = (
  router: Router,
  locale: Locale,
  shopifyRedirect: boolean,
) => {
  // Shopify redirect handler
  useEffect(() => {
    if (!shopifyRedirect) {
      return
    }

    //  Redirect to checkout
    const checkoutUrl = localStorage.getItem(getCheckoutUrlStorageKey(locale))

    if (checkoutUrl) {
      window.location.href = checkoutUrl
      return
    }

    // Redirect to homepage
    const url = getLinkPageUrl('homePage')
    router.push(url, url, {
      locale,
    })
  }, [locale, shopifyRedirect, router])
}

const Site = ({ pageProps, router, children }: SiteProps) => {
  const { isRouteChanging } = useContext(SiteContext)

  // Handle special Shopify redirect page
  useShopifyRedirect(router, pageProps.locale, !!pageProps.shopifyRedirect)

  // Handle router events & scroll position restoration
  useRouterEvents(router, pageProps.locale)

  // Handle keyboard navigation
  useEffect(() => {
    function handleKeyDown({ key }: KeyboardEvent) {
      // Check if "tab" key was pressed
      if (key === 'Tab' && typeof window !== 'undefined') {
        document.body.classList.add('is-tabbing')
        window.removeEventListener('keydown', handleKeyDown)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  // Trigger pageview on page load, if this is the first render and there's no query string
  useEffect(() => {
    if (!router.asPath.includes('?')) {
      triggerGoogleTagManagerEvent(pageViewEventName, {
        pagePath: window.location.pathname,
        pageTitle: document.title,
      })
      triggerGoogleTagManagerEvent(pageLoadEventName, {
        pagePath: window.location.pathname,
        pageTitle: document.title,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={cx('font-sans', gtWalsheim.variable)}>
      {isRouteChanging && pageProps.site?.strings?.loadingPageTitle && (
        <Head>
          <title>{pageProps.site.strings.loadingPageTitle}</title>
        </Head>
      )}

      <RouteChangeProgressBar />

      <LazyMotion features={domAnimation}>
        <AnimatePresence
          mode="wait"
          onExitComplete={() =>
            document.body.classList.remove('overflow-hidden')
          }
        >
          {children}
        </AnimatePresence>
      </LazyMotion>

      <CartModal cartSettings={pageProps.site?.cartSettings} />

      <ExternalScripts settings={pageProps.site?.generalSettings} />
    </div>
  )
}

const CustomApp = ({ Component, pageProps, router }: CustomAppProps) => {
  if (!pageProps.site) {
    return <Component />
  }

  let app = (
    <StringsContextProvider site={pageProps.site}>
      <SiteContextProvider site={pageProps.site}>
        <MetadataContextProvider>
          <PartnerAdsContextProvider
            enabled={!!pageProps.site?.generalSettings?.partnerAdsTracking}
          >
            <ShopContextProvider
              locale={pageProps.locale}
              site={pageProps.site}
              productCatalogue={pageProps.productCatalogue ?? []}
            >
              <LanguageContextProvider
                locale={pageProps.locale}
                site={pageProps.site}
              >
                <ProductContextProvider>
                  <Site pageProps={pageProps} router={router}>
                    <Component
                      key={router.asPath.split('?')[0]}
                      {...pageProps}
                    />
                  </Site>
                </ProductContextProvider>
              </LanguageContextProvider>
            </ShopContextProvider>
          </PartnerAdsContextProvider>
        </MetadataContextProvider>
      </SiteContextProvider>
    </StringsContextProvider>
  )

  if (pageProps.draftMode) {
    app = (
      <PreviewProvider
        token={pageProps.draftModeToken}
        studioUrl={pageProps.draftModeStudioUrl}
      >
        {app}
      </PreviewProvider>
    )
  }

  return app
}

export default CustomApp
