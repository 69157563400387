import cx from 'classnames'
import { useContext } from 'react'

import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'

interface ProductPriceProps {
  price: number
  comparePrice?: number
  inProductCard?: boolean
  inProductHero?: boolean
  inCartItem?: boolean
  className?: string
}

const ProductPrice = ({
  price,
  comparePrice,
  inProductCard,
  inProductHero,
  inCartItem,
  className,
}: ProductPriceProps) => {
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx(
        'flex',
        {
          'flex-col': inProductHero,
          'items-center': inProductCard,
        },
        className,
      )}
    >
      <span
        className={cx('font-semibold uppercase', {
          'sm:text-base sm:leading-normal': inProductCard,
          'text-xl leading-normal': inProductHero,
          'text-sm sm:text-lg sm:leading-normal': inCartItem,
        })}
      >
        {currency}
        {getPrice(price, taxRate)}
      </span>

      {!!comparePrice && (
        <span
          className={cx({
            'p-1 rounded-sm bg-pageText text-pageBG text-xxs leading-tight tracking-widest font-semibold uppercase text-center':
              inProductCard || inProductHero,
            'ml-2': inProductCard,
          })}
        >
          {strings.productDiscountText.replace(
            /{percent}/gi,
            Math.ceil(((comparePrice - price) / comparePrice) * 100).toString(),
          )}
        </span>
      )}
    </div>
  )
}

export default ProductPrice
