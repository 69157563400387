import { useNProgress } from '@tanem/react-nprogress'
import cx from 'classnames'
import { useContext } from 'react'

import { SiteContext } from '@lib/site-context'

const RouteChangeProgressBar = () => {
  const { isRouteChanging } = useContext(SiteContext)

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging,
  })

  return (
    <div
      className={cx('pointer-events-none', {
        'opacity-0': isFinished,
        'opacity-1': !isFinished,
      })}
      style={{
        transition: `opacity ${animationDuration}ms linear`,
      }}
    >
      {/* Filled-In Bar */}
      <div
        className="fixed w-full h-[2px] top-0 left-0 bg-black z-90"
        style={{
          marginLeft: `${(-1 + progress) * 100}%`,
          transition: `margin-left ${animationDuration}ms linear`,
        }}
      >
        {/* Shadow */}
        <div
          className="absolute block w-[100px] h-full right-0 opacity-100 shadow-black"
          style={{
            boxShadow: `0 0 10px var(--tw-shadow-color), 0 0 5px var(--tw-shadow-color)`,
            transform: 'rotate(3deg) translate(0px, -4px)',
          }}
        />
      </div>
    </div>
  )
}

export default RouteChangeProgressBar
