import { type SanityBorderRadius } from '@data/sanity/queries/types/image'

export const borderRadiusClassMap: Record<SanityBorderRadius, string> = {
  none: 'none',
  default: 'rounded',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full',
}
