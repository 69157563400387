import cx from 'classnames'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import {
  type HTMLAttributes,
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
  useContext,
} from 'react'

import {
  type SanityHubSpotFormUrl,
  type SanityLink,
} from '@data/sanity/queries/types/link'
import { useLogoutUser } from '@lib/auth'
import { FormValueContext } from '@lib/form-value-context'
import { LanguageContext } from '@lib/language-context'
import { type PageType, getLinkPageUrl, isRouteCollection } from '@lib/routes'
import { SiteContext } from '@lib/site-context'

import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import ButtonLink from '@components/buttons/button-link'

interface LinkProps {
  link: SanityLink
  onClick?: () => void
  children?: ReactNode
  showCollectionCount?: boolean
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
  nextLinkClassName?: string
}

type ExternalLinkProps = Pick<
  SanityLink,
  'url' | 'fontCase' | 'buttonStyle' | 'isButton'
> &
  Pick<
    LinkProps,
    'buttonVariant' | 'buttonSize' | 'buttonColor' | 'children'
  > & {
    id?: string
    ariaLabel?: string
    tabIndex?: number
    className?: string
  }

const ExternalLink = ({
  id,
  url,
  ariaLabel,
  tabIndex,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyle,
  isButton,
  fontCase,
  className,
  children,
}: ExternalLinkProps) => {
  const buttonStyles = isButton ? buttonStyle : undefined

  return (
    <ButtonLink
      id={id}
      href={url}
      target="_blank"
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      rel="noopener noreferrer"
      variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
      size={buttonSize ?? getButtonSize(buttonStyles?.size)}
      color={buttonColor ?? getButtonColor(buttonStyles?.color)}
      icon={buttonStyles?.icon}
      iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
      className={cx(
        {
          btn: isButton,
          'w-full': buttonStyles?.isFullWidth,
        },
        fontCase ?? '',
        className,
      )}
    >
      {children}
    </ButtonLink>
  )
}

type HubSpotLinkProps = Pick<
  SanityLink,
  'fontCase' | 'buttonStyle' | 'isButton'
> &
  Pick<
    LinkProps,
    'buttonVariant' | 'buttonSize' | 'buttonColor' | 'children'
  > & {
    id?: string
    hubSpotFormUrl: SanityHubSpotFormUrl
    ariaLabel?: string
    tabIndex?: number
    className?: string
  }

const HubSpotLink = ({
  id,
  hubSpotFormUrl,
  ariaLabel,
  tabIndex,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyle,
  isButton,
  fontCase,
  className,
  children,
}: HubSpotLinkProps) => {
  const { formValues } = useContext(FormValueContext)

  // Add dynamic URL parameters using form values
  const searchParams = new URLSearchParams()
  hubSpotFormUrl.dynamicParameters?.forEach(
    ({ source, hubSpotInternalName }) => {
      if (formValues && source in formValues) {
        searchParams.set(hubSpotInternalName, formValues[source])
      }
    },
  )

  return (
    <ExternalLink
      id={id}
      url={`${hubSpotFormUrl.url}?${searchParams.toString()}`}
      ariaLabel={ariaLabel}
      tabIndex={tabIndex}
      buttonVariant={buttonVariant}
      buttonSize={buttonSize}
      buttonColor={buttonColor}
      buttonStyle={buttonStyle}
      isButton={isButton}
      fontCase={fontCase}
      className={className}
    >
      {children}
    </ExternalLink>
  )
}

const Link = ({
  link,
  children,
  tabIndex,
  onClick,
  className,
  showCollectionCount,
  buttonVariant,
  buttonSize,
  buttonColor,
  'aria-label': ariaLabel,
  nextLinkClassName,
}: LinkProps & HTMLAttributes<HTMLAnchorElement>) => {
  const { getProductCount } = useContext(SiteContext)
  const { locale } = useContext(LanguageContext)
  const router = useRouter()
  const logoutUser = useLogoutUser()

  const pageType = link.page?._type
  const pageSlug = link.page?.slug?.current
  const isCollection = !!pageType && isRouteCollection(pageType)
  const collectionSlug = isCollection && pageSlug ? pageSlug : 'all'
  const collectionCount = getProductCount(collectionSlug)

  const { _id: id, url, hubSpotFormUrl, isButton, fontCase, buttonStyle } = link

  // HubSpot form link
  if (hubSpotFormUrl) {
    return (
      <HubSpotLink
        id={id}
        hubSpotFormUrl={hubSpotFormUrl}
        ariaLabel={ariaLabel}
        tabIndex={tabIndex}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyle={buttonStyle}
        isButton={isButton}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </HubSpotLink>
    )
  }

  // External link
  if (url) {
    return (
      <ExternalLink
        id={id}
        url={url}
        ariaLabel={ariaLabel}
        tabIndex={tabIndex}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyle={buttonStyle}
        isButton={isButton}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </ExternalLink>
    )
  }

  // Internal Page
  if (!pageType) {
    return null
  }

  const pageUrl = getLinkPageUrl(pageType, pageSlug)

  const getClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (pageType === 'logoutPage') {
      event.preventDefault()

      logoutUser(() => {
        const url = getLinkPageUrl('loginPage')
        router.push(url, url, {
          locale,
        })
      })
    }

    onClick && onClick()
  }

  const beforeInputHandler = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (pageType === 'logoutPage') {
      event.preventDefault()

      logoutUser(() => {
        const url = getLinkPageUrl('loginPage')
        router.push(url, url, {
          locale,
        })
      })
    }

    onClick && onClick()
  }

  const buttonStyles = isButton ? buttonStyle : undefined

  return (
    <NextLink href={pageUrl} className={nextLinkClassName}>
      <Button
        id={id}
        role="link"
        tabIndex={tabIndex}
        onClick={getClickHandler}
        onBeforeInput={beforeInputHandler}
        aria-label={ariaLabel}
        variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
        size={buttonSize ?? getButtonSize(buttonStyles?.size)}
        color={buttonColor ?? getButtonColor(buttonStyles?.color)}
        icon={buttonStyles?.icon}
        iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
        className={cx(
          {
            btn: isButton,
            'w-full': buttonStyles?.isFullWidth,
          },
          fontCase ?? '',
          className,
        )}
      >
        {children}

        {showCollectionCount && isCollection && (
          <span
            aria-hidden="true"
            className="inline-block relative ml-2 leading-none align-super text-[.5em]"
          >
            {collectionCount}
          </span>
        )}
      </Button>
    </NextLink>
  )
}

export default Link
