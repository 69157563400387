import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { CartContext } from './cart/context'
import {
  type DiscountItem,
  type DiscountsByType,
  getBundleDiscounts,
  getCartDiscountItems,
  getQuantityDiscounts,
} from './discount'

interface DiscountContextProps {
  discounts: DiscountsByType | null
  cartDiscountItems: DiscountItem[] | null
}

export const DiscountContext = createContext<DiscountContextProps>({
  discounts: null,
  cartDiscountItems: null,
})

interface DiscountContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const DiscountContextProvider = ({
  site,
  children,
}: DiscountContextProviderProps) => {
  const { cart } = useContext(CartContext)

  const discounts = useMemo<DiscountsByType>(
    () => ({
      quantityDiscounts: getQuantityDiscounts(site.discounts),
      bundleDiscounts: getBundleDiscounts(site.discounts),
    }),
    [site.discounts]
  )
  const [cartDiscountItems, setCartDiscountItems] = useState<
    DiscountItem[] | null
  >(null)

  // Calculate cart item discounts
  useEffect(() => {
    // Wait for cart to load
    if (!cart.id) {
      return
    }

    setCartDiscountItems(getCartDiscountItems(discounts, cart.lineItems))
  }, [cart.id, cart.lineItems, discounts])

  return (
    <DiscountContext.Provider value={{ discounts, cartDiscountItems }}>
      {children}
    </DiscountContext.Provider>
  )
}
