import cx from 'classnames'
import {
  type ChangeEvent,
  type FocusEvent,
  type HTMLInputTypeAttribute,
  type InputHTMLAttributes,
  useState,
} from 'react'
import { type Noop, type RefCallBack } from 'react-hook-form'

export interface InputChangeEvent {
  target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  type?: string
}

type InputChangeHandler = (event: InputChangeEvent) => Promise<void | boolean>
type ChangeHandler = (event: ChangeEvent) => void

// Combination of ControllerRenderProps and ControllerRenderProps from react-hook-form
export interface CustomFormRegister {
  ref: RefCallBack
  name: string
  value?: string | number
  onChange: InputChangeHandler | ChangeHandler | Noop
  onBlur: InputChangeHandler | Noop
}

interface InputFieldProps {
  type: HTMLInputTypeAttribute
  errorMessage?: string
  formRegister: CustomFormRegister
  label?: string
  borderBottom?: boolean
  isInvertedColors?: boolean
  inputClassName?: string
  children?: React.ReactNode
}

const InputField = ({
  id,
  type,
  value,
  defaultValue = '',
  autoComplete,
  placeholder,
  max,
  maxLength,
  label,
  errorMessage,
  borderBottom,
  formRegister,
  isInvertedColors,
  className,
  inputClassName,
  children,
}: InputFieldProps & InputHTMLAttributes<HTMLInputElement>) => {
  const isManaged = typeof value !== 'undefined'
  const [currentValue, setCurrentValue] = useState(defaultValue)

  const isEmail = type === 'email'
  const isNumber = type === 'number'

  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left')}>
        {label && (
          <label htmlFor={id} className="text-xs mb-2">
            {label}
          </label>
        )}
        <div
          className={cx('flex justify-between', {
            'border-error': errorMessage,
            'border-input-border': !errorMessage && !isInvertedColors,
            'border-input-inverted-border': !errorMessage && isInvertedColors,
            'mb-[1px] border-b focus-within:mb-0 focus-within:border-b-2':
              borderBottom,
            'border rounded-[3px]': !borderBottom,
          })}
        >
          <input
            id={id}
            type={type}
            inputMode={isEmail ? 'email' : isNumber ? 'decimal' : 'text'}
            max={max}
            maxLength={maxLength}
            autoComplete={autoComplete}
            ref={formRegister.ref}
            name={formRegister.name}
            value={isManaged ? value : currentValue}
            placeholder={placeholder}
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              formRegister.onBlur(event)
              setCurrentValue(event.target.value)
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              formRegister.onChange(event)
              setCurrentValue(event.target.value)
            }}
            className={cx(
              'relative appearance-none w-full h-full py-3 text-base leading-none',
              {
                inverted: isInvertedColors,
                'px-4': !borderBottom,
                'bg-input-bg text-input-text': !isInvertedColors,
                'bg-input-inverted-bg text-input-inverted-text':
                  isInvertedColors,
              },
              inputClassName,
            )}
          />
          {children}
        </div>
        {errorMessage && (
          <span role="alert" className="mt-2 text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default InputField
