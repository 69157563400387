import groq from 'groq'

import { simplePortableTextFragment } from './content'
import { imageFragment } from './image'

const productPhotosFragment = groq`
  galleryPhotos[] {
    forOption,
    photos[] {
      ${imageFragment}
    },
  },
  listingPhotos[] {
    forOption,
    listingPhoto {
      ${imageFragment}
    },
    listingPhotoHover {
      ${imageFragment}
    },
  },
`

const productVariantFragment = groq`
  sku,
  barcode,
  title,
  price,
  comparePrice,
  inStock,
  lowStock,
  seo,
  options[],
  variantID,
`

export const productFragment = groq`
  _createdAt,
  type,
  sku,
  barcode,
  title,
  price,
  comparePrice,
  inStock,
  lowStock,
  useGallery,
  surfaceOption,
  options[],
  optionNames[],
  slug,
  productID,
  optionSettings[] {
    forOption,
    "color": color->color {
      hex,
    },
  },
  filters[] {
    forOption,
    "slug": filter->slug,
  },
  description[] {
    ${simplePortableTextFragment}
  },
  "variants": *[
    _type == "productVariant" &&
    parentId == ^._id &&
    !wasDeleted &&
    !isDraft
  ] {
    ${productVariantFragment}
  },
  ${productPhotosFragment}
`

export const reviewProductFragment = groq`
  productID,
  slug,
  sku,
  title,
  ${productPhotosFragment}
`

const minimalContentProductFragment = groq`
  _id,
  locale,
  modules[],
  description[] {
    ${simplePortableTextFragment}
  },
`

export const productsByShopifyIdQuery = groq`
  *[
    _type == "product" &&
    productID == $shopifyProductId
  ] {
    ${minimalContentProductFragment}
  }
`

const variantQueryFragment = groq`
  title,
  price,
  sellingPlanId,
  options[],
  variantID,
  "product": *[
    _type == "product" &&
    _id == ^.parentId
  ][0] {
    productID,
    slug,
    title,
    cartPhotos[] {
      forOption,
      cartPhoto {
        ${imageFragment}
      },
    },
  },
`

export const productVariantQuery = groq`
  *[
    _type == "productVariant" &&
    variantID == $shopifyProductVariantId &&
    locale == $locale
  ][0] {
    ${variantQueryFragment}
  }
`

export const productVariantsQuery = groq`
  *[
    _type == "productVariant" &&
    variantID in $shopifyProductVariantIds &&
    locale == $locale
  ] {
    ${variantQueryFragment}
  }
`

export const productVariantsByShopifyIdQuery = groq`
  *[
    _type == "productVariant" &&
    productID == $shopifyProductId
  ] {
    _id,
    variantID,
  }
`

const catalogueProductFragment = groq`
  type,
  title,
  productID,
  slug,
  "subscriptionProductIds": subscriptionProducts[]->productID,
`

export const productCatalogueQuery = groq`
  *[
    _type == "product" &&
    locale == $locale &&
    !wasDeleted &&
    !isDraft
  ] {
    ${catalogueProductFragment}
  }
`

export const productExistsQuery = groq`
  *[
    _type == "product" &&
    _id == $id
  ][0] {
    _id,
  }
`
