import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { parseOptionalParameters, parseRequiredParameter } from './helpers'

export interface UrlParameter {
  name: string
  value?: string | string[] | null
}

type UrlParameters = Record<string, string[] | undefined>

/**
 * Previous value hook.
 */
export function usePrevious<T>(value: T) {
  const previousValue = useRef<T>()

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return previousValue.current
}

/**
 * Gets and updates multiple URL parameters.
 */
export const useUrlParameters = (defaultUrlParameters: UrlParameter[]) => {
  const router = useRouter()

  // Get current URL query parameters
  const urlParameters = useMemo(
    () =>
      defaultUrlParameters.map((parameter) => ({
        name: parameter.name,
        value: router.isReady
          ? parseRequiredParameter(router.query[parameter.name]) ?? null
          : undefined,
      })),
    [defaultUrlParameters, router],
  )

  // Update URL query parameters on change
  const setUrlParameters = useCallback(
    (parameters: UrlParameter[]) => {
      // Remove parameters that match default parameters (with both key and value)
      const filteredUrlParameters = parameters.filter(
        (parameter) =>
          !defaultUrlParameters.some(
            (defaultUrlParameter) =>
              defaultUrlParameter.name === parameter.name &&
              defaultUrlParameter.value === parameter.value,
          ),
      )
      const parsedUrlParameters = filteredUrlParameters.reduce(
        (previousValue, filteredUrlParameter) => {
          const value =
            filteredUrlParameter.value &&
            Array.isArray(filteredUrlParameter.value)
              ? filteredUrlParameter.value
              : filteredUrlParameter.value?.split(',')

          return {
            ...previousValue,
            [filteredUrlParameter.name]: value,
          }
        },
        {} as UrlParameters,
      )
      const urlQueryString = queryString.stringify(parsedUrlParameters, {
        arrayFormat: 'comma',
      })

      // Replace current URL with new parameters
      const slugs = parseOptionalParameters(router.query.slug) ?? []
      const currentPath = slugs.join('/')
      router.replace(
        `${currentPath}${urlQueryString ? `?${urlQueryString}` : ''}`,
        undefined,
        {
          shallow: true,
          scroll: false,
        },
      )
    },
    [defaultUrlParameters, router],
  )

  return [urlParameters, setUrlParameters] as const
}
