import { type SanityClient } from '@sanity/client'

import { productPageQuery } from '@data/sanity/queries/page'
import {
  productCatalogueQuery,
  productVariantQuery,
  productVariantsQuery,
} from '@data/sanity/queries/product'
import { type SanityProductPageQuery } from '@data/sanity/queries/types/page'
import {
  type SanityLocaleQueryParameter,
  type SanityProductSlugQueryParameter,
  type SanityShopifyProductVariantIdQueryParameter,
  type SanityShopifyProductVariantIdsQueryParameter,
} from '@data/sanity/queries/types/parameters'
import {
  type SanityProductCatalogueQuery,
  type SanityProductVariantQuery,
  type SanityProductVariantsQuery,
} from '@data/sanity/queries/types/product'
import { fetchQuery } from './client'
import {
  defaultModuleQueryParameters,
  SanityModuleQueryParameters,
} from './page'

/**
 * Fetches product page data based on product slug.
 */
export const getProductPage = async (
  sanityClient: SanityClient,
  locale: string,
  productSlug: string
) =>
  fetchQuery<
    SanityProductPageQuery,
    SanityLocaleQueryParameter &
      SanityProductSlugQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, productPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    productSlug,
  })

/**
 * Fetches a product variant based on its ID.
 */
export const getProductVariant = async (
  sanityClient: SanityClient,
  locale: string,
  shopifyProductVariantId: number
) =>
  fetchQuery<
    SanityProductVariantQuery | null,
    SanityLocaleQueryParameter & SanityShopifyProductVariantIdQueryParameter
  >(sanityClient, productVariantQuery, {
    locale,
    shopifyProductVariantId,
  })

/**
 * Fetches product variants based on a list of ID.
 */
export const getProductVariants = async (
  sanityClient: SanityClient,
  locale: string,
  shopifyProductVariantIds: number[]
) =>
  fetchQuery<
    SanityProductVariantsQuery,
    SanityLocaleQueryParameter & SanityShopifyProductVariantIdsQueryParameter
  >(sanityClient, productVariantsQuery, {
    locale,
    shopifyProductVariantIds,
  })

/**
 * Gets all product catalogue.
 */
export const getProductCatalogue = async (
  sanityClient: SanityClient,
  locale: string
) =>
  fetchQuery<SanityProductCatalogueQuery, SanityLocaleQueryParameter>(
    sanityClient,
    productCatalogueQuery,
    {
      locale,
    }
  )
