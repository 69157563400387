import cx from 'classnames'
import { useContext } from 'react'

import { CartContext } from '@lib/cart/context'
import { useCartTotals } from '@lib/cart/hooks'
import { DiscountContext } from '@lib/discount-context'

import CartDiscount from './discount'
import CartSubtotal from './subtotal'
import CartTotal from './total'

interface CartSummaryProps {
  className?: string
}

const CartSummary = ({ className }: CartSummaryProps) => {
  const { subTotal, totalDiscount, total } = useCartTotals()
  const { cart } = useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)

  const automaticDiscount = cart?.automaticDiscount ?? null

  return (
    <div
      className={cx('border-t border-pageText py-6 sm:py-8 sm:px-4', className)}
    >
      <CartSubtotal price={subTotal} />

      {automaticDiscount.amount > 0 && (
        <CartDiscount
          title={automaticDiscount.title}
          amount={automaticDiscount.amount}
          className="mt-3"
        />
      )}

      {cartDiscountItems?.map(({ id, title, amount, quantity }) => (
        <CartDiscount
          key={id}
          title={title}
          amount={amount}
          quantity={quantity}
          className="mt-3"
        />
      ))}

      <CartTotal price={total - totalDiscount} className="mt-3" />
    </div>
  )
}

export default CartSummary
