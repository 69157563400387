import { ReactNode, createContext, useEffect, useMemo } from 'react'

import { processPartnerAdsUrlParameters } from './partner-ads'

interface PartnerAdsContextProps {
  partnerAdsEnabled: boolean
}

interface PartnerAdsContextProviderProps {
  enabled: boolean
  children: ReactNode
}

export const PartnerAdsContext = createContext<PartnerAdsContextProps>({
  partnerAdsEnabled: false,
})

export const PartnerAdsContextProvider = ({
  enabled,
  children,
}: PartnerAdsContextProviderProps) => {
  // Get PartnerAds parameters on page load
  useEffect(() => {
    if (enabled) {
      processPartnerAdsUrlParameters()
    }
  }, [enabled])

  return (
    <PartnerAdsContext.Provider
      value={{
        partnerAdsEnabled: enabled,
      }}
    >
      {children}
    </PartnerAdsContext.Provider>
  )
}
