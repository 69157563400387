import Cookies from 'js-cookie'
import { useCallback, useContext } from 'react'

import { AttributeInput } from '@data/shopify/storefront/types'
import { PartnerAdsContext } from './partner-ads-context'

interface PartnerAdsVariables {
  partnerId?: string
  clickId?: string
}

const startTimeKey = 'x-pa-startTime'
const partnerIdKey = 'x-pa-id'
const clickIdKey = 'x-pa-pacid'

const storageExpiryInDays = 40
const storageExpiryInSeconds = 1000 * 60 * 60 * 24 * storageExpiryInDays

/**
 * Reads and processes PartnerAds IDs URL parameters and saves them.
 */
export const processPartnerAdsUrlParameters = () => {
  const url = new URL(window.location.href)

  if (
    !url.searchParams.has('pa-partnerid') ||
    (!url.searchParams.has('pacid') && !url.searchParams.has('pa-pacid'))
  ) {
    return
  }

  const date = new Date().getTime().toString()
  const partnerId = url.searchParams.get('pa-partnerid') ?? ''
  const clickId =
    url.searchParams.get('pacid') ?? url.searchParams.get('pa-pacid') ?? ''

  localStorage.setItem(startTimeKey, date)
  localStorage.setItem(partnerIdKey, partnerId)
  localStorage.setItem(clickIdKey, clickId)

  Cookies.set(startTimeKey, date, {
    expires: storageExpiryInDays,
  })
  Cookies.set(partnerIdKey, partnerId, {
    expires: storageExpiryInDays,
  })
  Cookies.set(clickIdKey, clickId, {
    expires: storageExpiryInDays,
  })
}

/**
 * Checks if the local storage item or cookie has expired.
 */
const isPartnerAdsExpired = () => {
  const date = new Date().getTime()
  const startTime =
    localStorage.getItem(startTimeKey) ?? Cookies.get(startTimeKey)

  if (startTime && date - Number(startTime) <= storageExpiryInSeconds) {
    return false
  }

  localStorage.removeItem(partnerIdKey)
  localStorage.removeItem(clickIdKey)

  Cookies.remove(partnerIdKey)
  Cookies.remove(clickIdKey)

  return true
}

/**
 * Gets stored PartnerAds parameters.
 */
const getPartnerAdsParameters = () => {
  if (isPartnerAdsExpired()) {
    return
  }

  return {
    partnerId: localStorage.getItem(partnerIdKey) ?? Cookies.get(partnerIdKey),
    clickId: localStorage.getItem(clickIdKey) ?? Cookies.get(clickIdKey),
  } satisfies PartnerAdsVariables
}

/**
 * Returns a hook that gets attributes with PartnerAds data.
 */
export const useGetPartnerAdsAttributes = () => {
  const { partnerAdsEnabled } = useContext(PartnerAdsContext)

  return useCallback(
    (isPrivate: boolean): AttributeInput[] => {
      if (!partnerAdsEnabled) {
        return []
      }

      const parameters = getPartnerAdsParameters()

      if (!parameters?.partnerId || !parameters?.clickId) {
        return []
      }

      const keyPrefix = isPrivate ? '_' : ''
      const partnerIdAttributeKey = `${keyPrefix}${partnerIdKey}`
      const clickIdAttributeKey = `${keyPrefix}${clickIdKey}`

      return [
        {
          key: partnerIdAttributeKey,
          value: parameters.partnerId,
        },
        {
          key: clickIdAttributeKey,
          value: parameters.clickId,
        },
      ]
    },
    [partnerAdsEnabled]
  )
}
