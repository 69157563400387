import { type Locale } from './language'

type LocaleEnvironmentVariableKey =
  | 'NEXT_PUBLIC_SHOPIFY_STORE_ID'
  | 'NEXT_PUBLIC_SHOPIFY_API_TOKEN'
  | 'SHOPIFY_API_PASSWORD'
  | 'SHOPIFY_WEBHOOK_INTEGRITY'
  | 'RECHARGE_API_TOKEN'
  | 'REPORT_URI_SUBDOMAIN'
  | 'FACEBOOK_CONVERSIONS_API_TOKEN'

type LocaleEnvironmentVariableValues = Record<
  LocaleEnvironmentVariableKey,
  string | undefined
>

type LocaleEnvironmentVariableMap = Record<
  Locale,
  LocaleEnvironmentVariableValues
>

const defaultLocaleEnvironmentVariables: LocaleEnvironmentVariableValues = {
  NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID,
  NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN,
  SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD,
  SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY,
  RECHARGE_API_TOKEN: process.env.RECHARGE_API_TOKEN,
  REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN,
  FACEBOOK_CONVERSIONS_API_TOKEN: process.env.FACEBOOK_CONVERSIONS_API_TOKEN,
}

const localeEnvironmentVariables: LocaleEnvironmentVariableMap = {
  en: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_EN,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_EN,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_EN,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_EN,
    RECHARGE_API_TOKEN: process.env.RECHARGE_API_TOKEN_EN,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_EN,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_EN,
  },
  da: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_DA,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_DA,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_DA,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_DA,
    RECHARGE_API_TOKEN: process.env.RECHARGE_API_TOKEN_DA,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_DA,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_DA,
  },
  de: {
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_DE,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_DE,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_DE,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_DE,
    RECHARGE_API_TOKEN: process.env.RECHARGE_API_TOKEN_DE,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_DE,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_DE,
  },
}

/**
 * Gets environment variable for specific locale.
 */
export const getLocaleVariable = (
  locale: Locale,
  key: LocaleEnvironmentVariableKey,
) => {
  const localeValue = localeEnvironmentVariables[locale]?.[key]

  if (typeof localeValue === 'undefined' || localeValue === '') {
    return defaultLocaleEnvironmentVariables[key]
  }

  return localeValue
}
